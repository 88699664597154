<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_potting/pgb730"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551407961_PGB-730.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Intelligent Glue Potting Machine PGB-730
                </h4>
         
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_potting/sz441"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1572396645_%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 Intelligent vision piezoelectric spraying valve glue dispenser SZV441
                </h4>
              
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_potting/pgb810"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551406131_PGB-710.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  High Precision Glue Potting Machine PGB-810
                </h4>
               
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_potting/pgb1500"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1527560868_PGB1500.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
Glue Potting Machine PGB-1500
                </h4>
               
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_potting/pgb200"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551338532_PGB200.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 AB Glue potting machine PGB-200
                </h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_potting/pgb5100"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551255342_PGB5100.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Two components silicone glue dispensing machine PGB-5100 
                </h4>
              
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_potting/dpj100"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551332895_DPJ100.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Large Flow Glue Potting Machine DPJ-100
                </h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_potting/tg50"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551334477_TG50.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 High Precision Glue Potting Machine TG-50
                </h4>
               
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>

      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_potting/qgb400"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551339443_QGB400.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Glue Potting Machine For Product Line QGB-400 
                </h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_potting/pgv502"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551411659_PGV-502.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Large Vision Visual Glue Potting Machine PGV-502
                </h4>
               
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/glue_potting/pgv3000">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551419689_PGV3000.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Visual Glue Potting Machine PGV-3000
                </h4>
               
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_potting/pjl1000"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551420586_PJL-1000.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title"> Economy Glue Potting Machine PJL-1000</h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/glue_potting/vgb600">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="http://v4-upload.goalsites.com/141/image_1551834407_VGB600.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Standalone Vacuum Glue Potting Machine VGB-600</h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_potting/vgb450"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="	http://v4-upload.goalsites.com/141/image_1551837114_VGB450Q.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Vacuum Glue Potting Machine With Production Line VGB-450
                </h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/glue_potting/pgv501"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="	http://v4-upload.goalsites.com/141/image_1572491176_2.jpg?imageView2/1/w/400/h/360/q/100"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  Vision glue potting machine PGV501
                </h4>
                
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
       
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>